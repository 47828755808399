<template>
  <div class="container">
    <b-card style="width: fit-content">
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 159, 67, 0.15)'"
          variant="none"
          to="/marketplace/manage"
          v-b-tooltip.hover
          title="Go Back to the Script List"
        >
          <feather-icon
            icon="ArrowLeftCircleIcon"
            size="25"
            class="d-inline text-warning"
          />
        </b-button>
        <h3 class="d-inline">Full Details of {{ name }}</h3>
      </b-col>
    </b-card>
    <b-row>
      <b-col class="col-md-4">
        <b-card id="appInfoLabel">
          <h4 class="text-center">Item's information</h4>
        </b-card>
      </b-col>
    </b-row>
    <div class="row justify-content-center">
      <div class="col-sm-6 col-md-8">
        <b-card id="appInfoMain">
          <div class="image-container">
            <div class="image-wrapper">
              <div
                v-for="(screen, index) in screens"
                :key="index"
                class="image-item"
              >
                <img :src="screen" alt="Screenshot" loading="lazy" />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="h3 text-dark">Description :</div>
            <p class="h5 description-text text-dark">
              {{ description }}
            </p>
          </div>
        </b-card>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="fixed-card">
          <b-card class="container">
            <div class="row justify-content-between">
              <div class="col-sm-7">
                <p>Regular Licenece</p>
              </div>
              <div class="col-sm-5">
                <b>FREE <Feather-icon icon="GiftIcon" /></b>
              </div>
            </div>
            <hr class="my-1" />
            <div class="row justify-content-between mb-2">
              <div class="col-sm-12 mb-1">
                <div class="h6 text-dark">
                  <feather-icon icon="CheckIcon" /> SIZE :
                  {{ fileSize | formatFileSize }}
                </div>
              </div>
              <div class="col-sm-12 mb-1">
                <div class="h6 text-dark">
                  <feather-icon icon="CheckIcon" /> Creation Date :
                  {{ createdAt }}
                </div>
              </div>
              <div class="col-sm-12 mb-1">
                <div class="h4 text-dark">
                  <feather-icon icon="CheckIcon" /> Downloads: {{ nbDownloads }}
                </div>
              </div>
            </div>

            <a :href="zip_urls" target="__blank">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="success"
                block
              >
                Download File <feather-icon icon="DownloadIcon" />
              </b-button>
            </a>
          </b-card>

          <b-card-group deck>
            <b-card title="TAGS">
              <b-row v-for="(tagChunk, index) in tagsInChunks" :key="index">
                <b-col v-for="tag in tagChunk" :key="tag">
                  <b-badge>#{{ tag }}</b-badge>
                </b-col>
              </b-row>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormTextarea,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BModal,
  VBModal,
  BFormFile,
  BCardText,
  BCardGroup,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import { getAccessTokenFromCookie } from "@/auth/utils";

export default {
  components: {
    BTable,
    BAvatar,
    "b-tooltip": VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BCardGroup,
    BModal,
    VBModal,
    BCardText,
    BContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      name: null,
      image_url: null,
      screens: [],
      description: null,
      zip_urls: [],
      tags: [],
      nbDownloads: null,
      createdAt: null,
      fileSize: null,
      selectedRating: 0,
      feedback: null,
    };
  },
  computed: {
    tagsInChunks() {
      const chunkSize = 3; // Number of badges in each row
      const tagsCopy = [...this.tags]; // Make a copy of the original tags array
      const chunks = [];

      while (tagsCopy.length) {
        chunks.push(tagsCopy.splice(0, chunkSize));
      }

      return chunks;
    },
  },

  filters: {
    formatFileSize(sizeInBytes) {
      if (sizeInBytes === null || isNaN(sizeInBytes)) return "N/A";

      const units = ["B", "KB", "MB", "GB", "TB"];
      let index = 0;
      while (sizeInBytes >= 1024 && index < units.length - 1) {
        sizeInBytes /= 1024;
        index++;
      }

      return `${sizeInBytes.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${
        units[index]
      }`;
    },
  },
  mounted() {
    const data = getAccessTokenFromCookie();
    const userType = data[2].role;
    if (userType !== "admin") {
      router.push({ name: "error-404" });
    }
    this.id = this.$route.params.id;
    this.getDetailScript();
  },
  methods: {
    setRating(rating) {
      this.selectedRating = rating;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    },
    getDetailScript() {
      try {
        axios
          .post("/get-mrktp-scripts-by-id", {
            id: this.id,
          })
          .then((response) => {
            this.name = response.data.script.name;
            this.createdAt = response.data.script.created_at;
            this.createdAt = this.formatDate(this.createdAt);
            this.image_url = response.data.script.image_url;
            this.description = response.data.script.description;
            this.zip_urls = response.data.script.zip_urls;
            this.nbDownloads = response.data.script.downloads;
            this.tags = response.data.tags;
            this.fileSize = response.data.zip_file_size;
            this.screens = response.data.script.screens;
            var cleanedData = this.screens.replace(/\\\//g, "/");
            this.screens = JSON.parse(cleanedData);
          });
      } catch (error) {
        console.error(error);
      }
    },
    saveReview() {
      alert(this.selectedRating);
    },
  },
  beforeRouteEnter(to, from, next) {
    axios.post("/isAdmin").then(({ data }) => {
      if (data.status === true) {
        next(true);
      } else if (data.status === false) {
        next("/error-404");
      }
    }).catch((error)=>{
      next("/login")
    });
  },
};
</script>

<style scoped>
.image-container {
  overflow-x: auto;
  display: flex;
}

.image-wrapper {
  display: flex;
}

.image-item {
  flex: 0 0 auto;
  margin-right: 10px;
  max-width: 100%;
}

.image-item img {
  width: 100%;
  height: 70vh;
  /* max-width: 250px; */
}

.image-container::-webkit-scrollbar {
  width: 5px;
}

.image-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.image-container::-webkit-scrollbar-thumb {
  background-color: #050404;
  border-radius: 5px;
}

.image-container::-webkit-scrollbar-thumb:hover {
  background-color: rgb(7, 7, 60);
}

.image-container::-webkit-scrollbar-button {
  display: none;
}

del {
  font-size: 1.5rem;
  font-weight: 900;
}

#appInfoLabel {
  margin: 0;
  padding: 0;
}

#appInfoLabel .card-body {
  margin: 0;
  padding: 13px 0;
}

#appInfoMain,
#appInfoLabel {
  border-radius: 0;
}

.app-title {
  font-size: 3rem !important;
  margin-bottom: 26px;
}

.fixed-card {
  position: sticky;
  top: 20px;
}

.description-text {
  font-size: 18px;
  line-height: 1.5;
  padding-inline: 2rem;
}

.star-rating {
  font-size: 24px;
}

.star-rating span {
  cursor: pointer;
}

.star-rating span.active {
  color: gold;
}

.ratedIcon {
  color: yellow;
}

div.app-content.content
  > div.content-wrapper
  > div
  > div
  > div:nth-child(1)
  > div {
  padding-block: 3px !important;
  padding-left: 3px !important;
}
</style>